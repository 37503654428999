<template>
<v-container fluid class="pa-0">
  <v-container class="d-flex flex-wrap justify-center align-center">
     <v-col cols="12 mb-5" >
         <h1 class="page-title">Convênios</h1>
      </v-col>
      <v-col v-if="covenantsHome.length === 0" class="d-flex flex-column justify-center align-center" cols="12">
        <v-img src="../../../../assets/Pulse-0.7s-200px.svg" max-width="50"></v-img>
        <h5 class="pa-0">Carregando...</h5> 
      </v-col>
      <v-col cols="12" sm="6" md="6" lg="4" xl="3" v-for="(card, index) in covenantsHome" :key="index" >
        <card :title="card.title" :id="card.id" type="convenants" :category="card.category" :file="{}" :url="card.url" @open="openPost($event)" :height="238"/>
      </v-col>
  </v-container>
  </v-container>
</template>

<script>
import covenantsService from '../../../../service/covenantsService'

export default {
  name: "covenants",
   metaInfo: { 
    title: "SEEB | Convênios",
     meta: [
      { name: 'description', content: 'Nossos convênios' }
    ]
    
  },
  methods:{
    openPost(id){
        // this.$router.push({ name: 'Post', params: { id: id, text:'Convênios', href:'/covenants', type:'covenants'} })
        // this.$router.push({ path: `/post/${'Convênios'}/${'/covenants'}/${id}` })
        this.$router.push({ path: `/post/covenants/${id}`})

  },
    getCovenants(){
      
      covenantsService.get({}).then(res => {
          
       this.covenantsHome = res.data.map(covenants => {
              return {
                id:covenants.id,
                title:covenants.title,
                category:covenants.category,
                date:new Date(covenants.created_at).toLocaleString(),
                url: covenants.path_img_card
              }
          })
      })
    }
  },
  mounted(){
    this.getCovenants()
  },
  components:{
    Card:() => import("../../../../components/commons/Card"),
    Footer: () => import("@/layouts/web/commons/Footer.vue")
  },
  data() {
    return {
      covenantsHome:[]
    };
  }
};
</script>

<style lang="sass">
  .card-image
    background-size: cover
    background-repeat: no-repeat
  
  .page-title 
    background: #114979
    color: #fff
    font-family: Raleway,sans-serif
    font-weight: 700
    line-height: 1
    margin: 0
    font-size: 1.75rem
    padding: 1.25rem 1.25rem
</style>
